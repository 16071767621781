import 'core-js/stable'
import Vue from 'vue'
import App from './App'
import router from './router'
import CoreuiVue from '@coreui/vue'
import { iconsSet as icons } from './assets/icons/icons.js'
import store from './store'
import 'regenerator-runtime/runtime'

Vue.config.performance = true
Vue.use(CoreuiVue)
Vue.prototype.$log = console.log.bind(console)

import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)

// Validation
import { ValidationProvider } from 'vee-validate'
Vue.component('ValidationProvider', ValidationProvider)

// Notifications
import Notifications from 'vue-notification'
Vue.use(Notifications)

// Firebase Authentication
import firebase from 'firebase/app'
import 'firebase/auth'
const configOptions = {
  apiKey: "AIzaSyAN972a9wAOiUxX00nPrYiP3-_9LabEntc",
  authDomain: "lottokey88.firebaseapp.com",
  databaseURL: "https://lottokey88.firebaseio.com",
  projectId: "lottokey88",
  storageBucket: "lottokey88.appspot.com",
  messagingSenderId: "431385767358",
  appId: "1:431385767358:web:442129cc059bb7ad15ddef",
  measurementId: "G-EBCSLQNMRV"
};
firebase.initializeApp(configOptions)

new Vue({
  el: '#app',
  router,
  store,
  icons,
  template: '<App/>',
  components: {
    App
  }
})
